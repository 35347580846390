<template>
  <div class="row price-detais-card">
    <div class="col-12">
      <hr>
      <div class="row custom-row">

        <div class="col-lg-8 scroll-y">

            <!-- Detalhes -->
            <div class=" custom-row mb-3 p-3">
              <!-- Taxas-->
              <div class="table-responsive borderless">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th width="30%">Taxas de Frete</th>
                    <th ></th>
                    <th>Tarifa / Unidade</th>
<!--                    <th>Tarifa / Mínimo</th>-->
                    <th>Qtde</th>
                    <th>Total</th>
                  </tr>
                  </thead>
                  <tbody v-if="taxasFrete && taxasFrete.length > 0 ">
                  <tr v-for="(taxa, index ) in taxasFrete" v-bind:key="index">
                    <td>
                      {{ taxa.descricao }}
                    </td>
                    <td>
                      {{ taxa.moeda }}
                    </td>
                    <td>
                      {{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}
                    </td>
<!--                    <td>-->
<!--                      {{ $util.numberFormat(taxa.valor_pagamento_minimo,2,',','.') }}-->
<!--                    </td>-->
                    <td>
                      {{ $util.formatNumber(taxa.quantidade) }}
                    </td>
                    <td>
                      {{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}
                    </td>

                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="5">Nenhuma taxa disponível</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- Taxas de origem-->
              <div class="table-responsive borderless" v-if="taxasOrigem && taxasOrigem.length > 0">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th width="30%">Taxas de Origem</th>
                    <th></th>
                    <th>Tarifa / Unidade</th>
<!--                    <th>Tarifa / Mínimo</th>-->
                    <th>Qtde</th>
                    <th>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(taxa, index ) in taxasOrigem" v-bind:key="index">
                    <td>
                      {{ taxa.descricao }}
                    </td>
                    <td>
                      {{ taxa.moeda }}
                    </td>
                    <td>
                      {{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}
                    </td>
<!--                    <td>-->
<!--                      {{ $util.numberFormat(taxa.valor_pagamento_minimo,2,',','.') }}-->
<!--                    </td>-->
                    <td>
                      {{ $util.formatNumber(taxa.quantidade) }}
                    </td>
                    <td>
                      {{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- Taxas de destino-->
              <div class="table-responsive borderless"  v-if="taxasDestino && taxasDestino.length > 0 ">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th width="30%">Taxas de Destino</th>
                    <th></th>
                    <th>Tarifa / Unidade</th>
                    <!--                    <th>Tarifa / Mínimo</th>-->
                    <th>Qtde</th>
                    <th>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(taxa, index ) in taxasDestino" v-bind:key="index">
                    <td>
                      {{ taxa.descricao }}
                    </td>
                    <td>
                      {{ taxa.moeda }}
                    </td>
                    <td>
                      {{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}
                    </td>
                    <!--                    <td>-->
                    <!--                      {{ $util.numberFormat(taxa.valor_pagamento_minimo,2,',','.') }}-->
                    <!--                    </td>-->
                    <td>
                      {{ $util.formatNumber(taxa.quantidade) }}
                    </td>
                    <td>
                      {{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
            <!-- FIM Detalhes -->
        </div>

        <div class="col-lg-4">
          <div class="row custom-row h-100 d-flex align-items-end">
            <div class="col-12">
              <div class="row pl-2">
                <div class="col-md-12 p-3">
                  <div class="small">
                     *Este pedido de cotação não garante a disponibilidade do equipamento, e sim uma pré-reserva.
                  </div>
                  <div class="small">
                    Reserva/booking está sujeito a disponibilidade de espaço e a confirmação do armador
                  </div>
                  <div class="small">
                    Após o envio, você receberá um contato de nossa equipe dentro de 24 horas.
                  </div>
                  <div class="small">
                    Clique no botão abaixo  para informar os dados da pré-reserva
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <!-- Total -->
              <div class="row custom-row mb-3 py-3">
                <!-- Desconto disponível -->
                <div class="col-12 pull-right text-right b-2" v-if="!isCupomAdded && hasCupomAvailable">
                  <p class="preco-total">{{  $t('form.proposal.desconto')  }}</p>
                  <div class="row">
                    <div class="col-12">
                      <div class="currency">
                        <span class="custom-btn-link" v-on:click="applyCupom()">Utilizar Desconto</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Desconto Adicionado -->
                <div class="col-12 pull-right text-right mb-2" v-if="isCupomAdded">
                  <p class="preco-total">{{  $t('form.proposal.desconto')  }}</p>
                  <div class="row">
                    <div class="col-12">
                      <div class="currency">
                        <span>{{ tariff.cupom.moeda }}</span> {{ $util.numberFormat(tariff.cupom.valor, 2, ',', '.') }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Total -->
                <div class="col-12 pull-right text-right">
                  <p class="preco-total">{{  $t('form.proposal.valor_total')  }}</p>
                  <div class="row">
                    <div class="col-12" v-for="(moeda, index) in totalMoeda" v-bind:key="index">
                      <div class="currency">
                        <span v-if="index != 0">+</span>
                        <span>{{ moeda.moeda }}</span> {{ $util.numberFormat(moeda.total, 2, ',', '.') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center px-2">
                  <label class="small valido-ate" v-html="$t('form.proposal.valido_de_ate', {from: $util.formatDateWritten(tariff.data_inicio_vigencia), to: $util.formatDateWritten(tariff.data_validade)})"></label>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button class="btn btn-sm btn-primary pull-right btn-select"
                    v-on:click="$emit('option-seleted')">Seguir com esses valores</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'PriceDetailsModal',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s'
    }
  },
  methods: {
    applyCupom () {
      let moeda = this.availableCupom.idmoeda.sigla

      if (!moeda) {
        moeda = this.tariff.moeda_frete
      }

      this.$set(this.tariff, 'cupom', {
        id: this.availableCupom.id,
        valor: this.availableCupom.valor,
        moeda: moeda
      })

      // // Para forçar a atualização dos totais (Não remover - Necessário buscar forma para que o metodo computed seja executado novamente )
      // this.tariff.total[0].total = this.tariff.total[0].total * -1.0
      // this.tariff.total[0].total = this.tariff.total[0].total * -1.0
    }
  },
  data () {
    return {
      fullPage: false,
      filesLoaded: false,
      templateVarList: [],

      idiom: [],
      type: [],
      showCH: false,
      showCT: false
    }
  },
  mounted () {
  },
  components: {
  },
  computed: {
    isCupomAdded () {
      let isAdded = false

      if (this.tariff.cupom && this.tariff.cupom.id !== null) {
        isAdded = true
      }

      return isAdded
    },
    hasCupomAvailable () {
      let hasAvalaible = false

      if (this.availableCupom) {
        return true
      }

      return hasAvalaible
    },

    taxasFrete () {
      let _this = this
      let taxas = _this.tariff.taxas.filter((e) => {
        return e.tipo === 1
      })

      if (taxas === undefined) {
        return []
      }

      return taxas
    },
    taxasOrigem () {
      let _this = this
      let taxas = _this.tariff.taxas.filter((e) => {
        return e.tipo === 2
      })

      if (taxas === undefined) {
        return []
      }

      return taxas
    },
    taxasDestino () {
      let _this = this
      let taxas = _this.tariff.taxas.filter((e) => {
        return e.tipo === 3
      })

      if (taxas === undefined) {
        return []
      }

      return taxas
    },
    totalMoeda () {
      let total = []

      for (let i in this.tariff.total) {
        let totalMoeda = this.tariff.total[i]
        let totalValor = totalMoeda.total

        total.push({
          moeda: totalMoeda.moeda,
          total: totalValor
        })
      }

      if (this.tariff.cupom && this.tariff.cupom.valor > 0) {
        let index = total.findIndex((e) => {
          return e.moeda === this.tariff.cupom.moeda
        })

        if (index !== -1) {
          total[index].total = total[index].total - this.tariff.cupom.valor
        } else {
          total.push({
            moeda: this.tariff.cupom.moeda,
            total: this.tariff.cupom.valor
          })
        }
      }

      return total
    }
  },
  props: {
    tariff: {
      type: Object,
      default: () => {
        return { }
      }
    },
    availableCupom: {
      type: Object,
      required: false
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 15px;
  z-index: 99;
}

label {
  font-weight: bold;
}
.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.table-responsive {
  padding: 0;
}

.table-responsive.borderless {
  border-bottom: 1px solid #ccc;
}

.price-detais-card .scroll-y {
  max-height: 320px;
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #84754E;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #918158;
}

.btn-select {
    width:100%;
    text-align: center;
}

.total.summary {
  border: 0;
  padding: 0;
}

.preco-total {
  color: #918158;
  font-weight: bold;
}

.valido-ate {
  color: black;
  font-weight: bolder;
}

.custom-btn-link {
  color: #3483fa !important;
}

.custom-btn-link:hover {
  color: #2968c8 !important;
  cursor: pointer;
}

@media (max-width: 992px) {
  .bellow {
    margin-bottom: 1.5rem !important;
  }

  .btn-tiny {
    float: none !important;
    margin: auto;
  }

  .above {
    margin-bottom: 2vh;;
  }

}
</style>
