<template>
  <div class="col-sm-4 form-group mb-4" :class="rootClassName">
    <label
      :class="[{ 'royalc-error-input': error, 'small grey-label': greyField }]" >
      {{ this.$i18n.t(label) }}
    </label>
    <v-select  v-if="!isView"
               name="nome"
              label="nome"
              :disabled="disabled"
              :clearable="false"
              :searchable="true"
              :class="[{ 'grey-field': greyField }]"
              v-model="selected"
              @search="fetchOptions"
              :options="optionList"
              @input="onSelect" :filter-by="filter" >
      <template slot="no-options" slot-scope="{searching, search }">
        <em v-if="!searching" style="opacity: 0.5;">
          Digite para buscar
        </em>
        <em v-else style="opacity: 0.5;">
          Nenhuma opção encontrada para {{ search }}
        </em>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          {{ option.nome }}<span v-if="!option.pais">{{ option.sigla ? ('(' + option.sigla + ')') : '' }}</span><span v-else>{{ option.pais ? (', ' + option.pais) : '' }}</span>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{ option.nome }}<span v-if="!option.pais">{{ option.sigla ? ('(' + option.sigla + ')') : '' }}</span><span v-else>{{ option.pais ? (', ' + option.pais) : '' }}</span>
        </div>
      </template>
    </v-select>
    <div v-else-if="isView">
      {{ selected.nome }}<span v-if="!selected.pais">{{ selected.sigla ? ('(' + selected.sigla + ')') : '' }}</span><span v-else>{{ selected.pais ? (', ' + selected.pais) : '' }}</span>
    </div>
    <transition name="slide" mode="">
      <small v-show="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>
import OriginDestinationService from '@/services/OriginDestinationService'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  inheritAttrs: false,
  name: 'OriginDestinationField',
  components: {
    vSelect
  },
  created () {
    let _this = this
    _this.id = 'origin_destino_' + _this._uid
  },
  mounted () {
    this.selected = this.value
  },
  data () {
    return {
      optionList: [],
      id: null,
      timer: 0,
      selected: null
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    type: {
      required: false,
      type: Number,
      default: 1
    },
    isView: {
      type: Boolean,
      required: false,
      default: false
    },
    greyField: {
      type: Boolean,
      required: false,
      default: true
    },
    showImpo: {
      type: Boolean,
      required: false,
      default: true
    },
    showExpo: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false,
      default: 'filters.filOrigem'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    value: {
      required: true
    },
    rootClassName: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    }
  },
  methods: {
    /**
     * Triggered when search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    fetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          loading(true)
          let filters = {
            'searchTerm': search,
            'type': _this.type
          }

          if (!_this.showExpo || !_this.showImpo) {
            filters.operation = (_this.showExpo) ? 1 : 2
          }

          OriginDestinationService.getOriginsByOperation(filters).then(res => {
            _this.optionList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    onSelect (val) {
      this.$emit('input', val)
    },
    filter (option, label, search) {
      let temp = search.toLowerCase().trim()

      return (
        option.nome.toLowerCase().indexOf(temp) > -1 ||
        ((!option.pais && option.sigla) && option.sigla.toLowerCase().indexOf(temp) > -1) ||
        ((option.pais) && option.pais.toLowerCase().indexOf(temp) > -1)
      )
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    },
    type: function (newValue, oldValue) {
      this.optionList = []
      if (newValue && oldValue !== null) {
        this.selected = null
      }
    },
    showExpo: function (newValue) {
      this.optionList = []
    },
    showImpo: function (newValue) {
      this.optionList = []
    }
  }
}
</script>

<style>
.grey-label {
  color: gray;
  margin-bottom: 0;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}
</style>
