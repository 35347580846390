<template>
  <div>
    <div class="px-5 py-2">
      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"></loading>

      <div class="row justify-content-md-center">
        <!--   Search Bar   -->
        <div class="col-12 col-md-12 mt-5 mb-3">
            <search-bar v-model="filterData" v-on:search="onSearch" :equipment-list="equipmentList"/>
        </div>
      </div>
    </div>
    <!-- Sort Order -->
    <div class="px-5 py-2">
      <div class="row custom-row"  v-show="tariffOptions.length > 0">
        <div class="col-12 mb-2">
            Ordenar por:
        </div>
        <!-- Preço -->
        <card class-root="col-12 col-md-3 mb-4" :black="true"  v-on:click.native="togglePrecoSort()">
          <template slot="title">
            Preço
          </template>
          <template slot="sort">
            <span v-if="filteredByPrecoMaisAlto"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_up </span>
            <span v-if="filteredByPrecoMaisBaixo"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_down </span>
          </template>
          <template slot="content-1">
            <div class="value">
               {{ precoMaisBaixo }}
            </div>
            <div class="small">menor</div>
          </template>
          <template slot="content-2">
            <div class="value">
              {{ precoMaisAlto }}
            </div>
            <div class="small">maior</div>
          </template>
        </card>
        <!-- Free Time -->
        <card class-root="col-12 col-md-3 mb-4" v-on:click.native="toggleFreeTimeSort()">
          <template slot="title">
            Free time
          </template>
          <template slot="sort">
            <span v-if="filteredByFreeTimeMaisAlto"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_up </span>
            <span v-if="filteredByFreeTimeMaisBaixo"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_down </span>
          </template>
          <template slot="content-1">
            <div class="value">
              {{ menorFreeTime }}
            </div>
            <div class="small">menor</div>
          </template>
          <template slot="content-2">
            <div class="value">
              {{ maiorFreeTime }}
            </div>
            <div class="small">maior</div>
          </template>
        </card>
        <!-- Transit Time -->
        <card class-root="col-12 col-md-3 mb-4" v-on:click.native="toggleTransitTimeSort()">
          <template slot="title">
            Transit time
          </template>
          <template slot="sort">
            <span v-if="filteredByTransitTimeMaisAlto"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_up </span>
            <span v-if="filteredByTransitTimeMaisBaixo"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_down </span>
          </template>
          <template slot="content-1">
            <div class="value">
              {{ menorTransitTime }}
            </div>
            <div class="small">menor</div>
          </template>
          <template slot="content-2">
            <div class="value">
              {{ maiorTransitTime }}
            </div>
            <div class="small">maior</div>
          </template>
        </card>
        <!-- Validade -->
        <card class-root="col-12 col-md-3 mb-4" v-on:click.native="toggleValidadeSort()">
          <template slot="title">
            Validade
          </template>
          <template slot="sort">
            <span v-if="filteredByValidadeMaisAlto"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_up </span>
            <span v-if="filteredByValidadeMaisBaixo"  class="material-icons-outlined" style="font-size: 33px;"> arrow_drop_down </span>
          </template>
          <template slot="content-1">
            <div class="value">
              {{ menorValidade }}
            </div>
            <div class="small">menor</div>
          </template>
          <template slot="content-2">
            <div class="value">
              {{ maiorValidade }}
            </div>
            <div class="small">maior</div>
          </template>
        </card>
      </div>
      </div>
    <!-- Sort Order -->
    <div class="mt-0 abaixo px-5 py-2" style="background-color: #fff">
      <div class="row custom-row justify-content-md-center">
        <div class="col-12">
            <div class="row">
              <!-- Content -->
              <div class="col-12 col-md-12">
                <!-- Sort Order -->
                <div class="row" v-if="tariffOptions.length > 0">
                  <div class="col-md-8 price-div">
                    <div v-if="showResultsFor">
                    Mostrando resultados para:
                      {{ searchTerms.origin.join(', ') }}
                      {{ searchTerms.destination.join(', ') }}
                    </div>
                  </div>
                  <div class="col-md-4">
                  </div>
                </div>
              </div>
            </div>

            <!-- Search Result -->
            <div class="row cinza" v-if="tariffOptions.length > 0">
              <search-result v-for="(tariffOption, index) in tariffOptions" :tariffOption="tariffOption"
                :availableCupom="availableCupom"
                v-bind:key="index" v-on:option-seleted="onOptionSelected" />
            </div>
            <div class="row" v-else-if="filtered">
              <!-- Nenhuma opção encontrada -->
              <div class="col-10 proposal-no-options pb-5" v-if="resultType === 1">
                <div class="float-left" style="width: 100px;">
                  <img src="/static/images/search_icon.png" alt="">
                </div>
                <div class="float-left text-content">
                  <h1 class="title">Opa! Não possuímos essa Origem X Destino por aqui.</h1>
                  <div>
                    Clique no botão abaixo para solicitar uma proposta manual do nosso time Comercial. Te contatamos logo, logo!
                  </div>
                  <button v-on:click="sendNoOption(filtered)" class="btn btn-sm btn-primary btn-fill px-4 py-2">Solicitar Proposta</button>
                </div>
                <div class="clearfix"></div>
              </div>

              <!-- Nenhuma opção encontrada na data específicada -->
              <div class="col-10 proposal-no-options pb-5" v-if="resultType === 2 || resultType === 4">
                <div class="float-left" style="width: 100px;">
                  <img src="/static/images/search_icon.png" alt="">
                </div>
                <div class="float-left text-content">
                  <h1 class="title">Ops! Não encontrei valores para a data {{ $util.formatDate(searchDate) }}.</h1>
                  <div class="mb-4">Tenho fretes com validade até o dia {{ $util.formatDate(maxValidity) }}. <a class="card-link btn-link" v-on:click="onSearchSuggested" >CLIQUE AQUI</a> para ver esses valores</div>
<!--                  <div class="mb-0" v-for="(option, index) in searchBy" v-bind:key="index">-->
<!--                    Opção {{ index+1 }}: {{ $util.formatDateWritten(option.Data_Inicio_Vigencia) }} à {{ $util.formatDateWritten(option.Data_Validade) }}-->
<!--                  </div>-->
                  <div class="mb-3">
                    Caso queira manter a previsão de embarque para o dia {{ $util.formatDate(searchDate) }}, um de nossos vendedores te enviará uma proposta comercial por e-mail.
                  </div>
                  <button v-on:click="sendNoOption(filtered)" class="btn btn-sm btn-primary btn-fill px-4 py-2">Manter data e solicitar Proposta</button>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'
import Card from '@/components/Cards/Card'
// Services
import EquipmentService from '@/services/EquipmentService'
import TariffService from '@/services/TariffService'
import moment from 'moment'
import ProposalService from '@/services/ProposalService'

export default {
  name: 'ProposalSearch',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.proposal') + ' - %s'
    }
  },
  data () {
    return {
      filtered: false,
      resultType: 1,
      searchBy: [],
      searchTerms: {
        origin: [],
        destination: []
      },
      maxValidity: null,
      searchDate: null,
      isLoading: true,
      fullPage: true,
      equipmentList: [],
      sortOrderCards: [],
      sortOrderList: [
        {
          id: 1,
          nome: 'Preço mais baixo',
          valor: null
        },
        {
          id: 2,
          nome: 'Preço mais alto',
          valor: null
        },
        {
          id: 3,
          nome: 'Free Time maior',
          valor: null
        },
        {
          id: 4,
          nome: 'Free Time menor',
          valor: null
        },
        {
          id: 5,
          nome: 'Transit time menor',
          valor: null
        },
        {
          id: 6,
          nome: 'Transit time maior',
          valor: null
        },
        {
          id: 7,
          nome: 'Validade de embarque menor',
          valor: null
        },
        {
          id: 8,
          nome: 'Validade de embarque maior',
          valor: null
        }
      ],
      // filterData
      filterData: {
        operation: {
          id: 2,
          nome: 'IMPORT'
        },
        modality: {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        incoterm: {
          id: 2,
          nome: 'FOB'
        },
        commoditie: {
          id: null,
          nome: null
        },
        origin: {
          id: null,
          nome: null
        },
        destination: {
          id: null,
          nome: null
        },
        estimatedDate: null,
        equipments: [],
        cbm: null,
        peso_bruto: null,
        showShipowner: null,
        sortBy: {
          id: 1,
          nome: 'Preço mais baixo'
        }
      },
      tariffOptions: [],
      availableCupom: null
    }
  },
  components: {
    SearchResult,
    Loading,
    SearchBar,
    Card
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  // Apply filters to local filter
  created () {
    let _this = this
    let params = _this.$route.params

    // Se nenhum filtro aplicado na tela anterior, carrega dados dos cookies
    if (params && Object.keys(params).length === 0 && params.constructor === Object) {
      let previousFilters = global.instanceApp.$Cookie.get('_proposal_last_search_')
      if (previousFilters !== undefined) {
        let today = moment()
        _this.filterData = JSON.parse(previousFilters)

        let selectedDate = moment(_this.filterData.estimatedDate)

        let days = selectedDate.diff(today, 'days')

        if (days < 1) {
          _this.filterData.estimatedDate = today.add(1, 'days').format()
        }
      }
    }

    /** @TODO: Load basilares data */
    _this.isLoading = true

    _this.equipmentLoadOptions().then(res => {
      _this.equipmentList = res
      // Filtered on previous page
      if (params && Object.keys(params).length > 0 && params.constructor === Object) {
        _this.filterData = _this.$route.params
        _this.onSearch(_this.filterData)
      }
    }).finally(
      _this.isLoading = false
    )
  },
  computed: {
    filteredByValidadeMaisAlto () {
      if (this.filterData.sortBy.id === 8) {
        return true
      }

      return false
    },
    filteredByValidadeMaisBaixo () {
      if (this.filterData.sortBy.id === 7) {
        return true
      }

      return false
    },
    filteredByTransitTimeMaisAlto () {
      if (this.filterData.sortBy.id === 6) {
        return true
      }

      return false
    },
    filteredByTransitTimeMaisBaixo () {
      if (this.filterData.sortBy.id === 5) {
        return true
      }

      return false
    },
    filteredByFreeTimeMaisAlto () {
      if (this.filterData.sortBy.id === 3) {
        return true
      }

      return false
    },
    filteredByFreeTimeMaisBaixo () {
      if (this.filterData.sortBy.id === 4) {
        return true
      }

      return false
    },
    filteredByPrecoMaisAlto () {
      if (this.filterData.sortBy.id === 2) {
        return true
      }

      return false
    },
    filteredByPrecoMaisBaixo () {
      if (this.filterData.sortBy.id === 1) {
        return true
      }

      return false
    },
    precoMaisBaixo () {
      let menorPreco = this.sortOrderCards.find((e) => {
        return e.id === 1
      })

      if (menorPreco) {
        menorPreco = menorPreco.valor_formatado
      }

      return menorPreco
    },
    precoMaisAlto () {
      let maiorPreco = this.sortOrderCards.find((e) => {
        return e.id === 2
      })

      if (maiorPreco) {
        maiorPreco = maiorPreco.valor_formatado
      }

      return maiorPreco
    },
    menorFreeTime () {
      let menor = this.sortOrderCards.find((e) => {
        return e.id === 4
      })

      if (menor) {
        menor = menor.valor_formatado
      }

      return menor
    },
    maiorFreeTime () {
      let maior = this.sortOrderCards.find((e) => {
        return e.id === 3
      })

      if (maior) {
        maior = maior.valor_formatado
      }

      return maior
    },
    menorTransitTime () {
      let menor = this.sortOrderCards.find((e) => {
        return e.id === 5
      })

      if (menor) {
        menor = menor.valor_formatado
      }

      return menor
    },
    maiorTransitTime () {
      let maior = this.sortOrderCards.find((e) => {
        return e.id === 6
      })

      if (maior) {
        maior = maior.valor_formatado
      }

      return maior
    },
    menorValidade () {
      let menor = this.sortOrderCards.find((e) => {
        return e.id === 7
      })

      if (menor) {
        menor = menor.valor_formatado
      }

      return menor
    },
    maiorValidade () {
      let maior = this.sortOrderCards.find((e) => {
        return e.id === 8
      })

      if (maior) {
        maior = maior.valor_formatado
      }

      return maior
    },
    showResultsFor () {
      if (this.searchTerms.origin.length > 0) {
        return true
      }

      if (this.searchTerms.destination.length > 0) {
        return true
      }

      return false
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    toggleValidadeSort () {
      let sort = this.sortOrderCards.find((e) => {
        return e.id === 7
      })

      if (this.filterData.sortBy.id === 7) {
        sort = this.sortOrderCards.find((e) => {
          return e.id === 8
        })
      }

      this.selectSortOrder(sort)
    },
    togglePrecoSort () {
      let sort = this.sortOrderCards.find((e) => {
        return e.id === 1
      })

      if (this.filterData.sortBy.id === 1) {
        sort = this.sortOrderCards.find((e) => {
          return e.id === 2
        })
      }

      this.selectSortOrder(sort)
    },
    toggleFreeTimeSort () {
      let sort = this.sortOrderCards.find((e) => {
        return e.id === 4
      })

      if (this.filterData.sortBy.id === 4) {
        sort = this.sortOrderCards.find((e) => {
          return e.id === 3
        })
      }

      this.selectSortOrder(sort)
    },
    toggleTransitTimeSort () {
      let sort = this.sortOrderCards.find((e) => {
        return e.id === 5
      })

      if (this.filterData.sortBy.id === 5) {
        sort = this.sortOrderCards.find((e) => {
          return e.id === 6
        })
      }

      this.selectSortOrder(sort)
    },
    getFreeTime (equipamentos) {
      if (!equipamentos) {
        return null
      }

      let freeTime = []

      for (let i = 0; i < equipamentos.length; i++) {
        let equipamento = equipamentos[i]

        if (equipamento.free_time_origem) {
          if (!freeTime.includes(equipamento.free_time_origem)) {
            freeTime.push(equipamento.free_time_origem)
          }
        }

        if (equipamento.free_time_destino) {
          if (!freeTime.includes(equipamento.free_time_destino)) {
            freeTime.push(equipamento.free_time_destino)
          }
        }
      }

      return freeTime
    },
    sendNoOption (searchInfo) {
      let _this = this
      _this.isLoading = true
      let formData = {}

      formData.searchInfo = searchInfo

      ProposalService.postSendNoOptions(formData).then((res) => {
        global.instanceApp.$Cookie.remove('_proposal_last_search_')
        _this.$alertSwal.modalAlert('', 'Solicitação de proposta enviada', 'success', null, {
          showConfirmButton: false
        })

        setTimeout(function () {
          _this.$router.push({ name: 'ProposalIndex' })
        }, 2000)
      }).finally(() => {
        _this.isLoading = false
      })
    },
    onOptionSelected (selectedOption) {
      this.isLoading = true
      let formData = {
        adicionais: {
          seguro: {}
        }
      }

      // Cria o rascunho com os dados iniciais
      if (selectedOption.carga && selectedOption.carga.seguroFreteMercadoria) {
        formData.adicionais.seguro.freteMercadoria = selectedOption.carga.seguroFreteMercadoria
      }

      if (selectedOption.cupom && selectedOption.cupom.id != null) {
        formData.cupom = {
          id: selectedOption.cupom.id
        }
      }

      formData.id_tarifario_maritimo = selectedOption.id_tarifario_maritimo
      formData.transitTime = selectedOption.transit_time
      formData.idSysCotacaoLog = this.filtered
      formData.incoterm = selectedOption.incoterm
      formData.peso_bruto = selectedOption.peso_bruto
      formData.cbm = selectedOption.cbm
      formData.data_embarque = selectedOption.data_embarque
      formData.data_validade = selectedOption.data_validade
      formData.data_inicio_vigencia = selectedOption.data_inicio_vigencia
      formData.origem = selectedOption.pol
      formData.adicionais.seguro.valor_frete = selectedOption.valor_frete
      formData.cliente = selectedOption.id_cliente
      formData.destino = selectedOption.pod
      formData.special = selectedOption.special
      formData.brandScac = selectedOption.brandScac
      formData.mercadoria = selectedOption.mercadoria
      formData.modalidadeProcesso = selectedOption.modalidade_processo
      formData.tipoOperacao = selectedOption.tipo_operacao
      formData.tipoCarga = selectedOption.tipo_carga
      formData.equipamentos = selectedOption.equipamentos
      formData.previsao_embarque = selectedOption.previsao_embarque
      formData.taxas = selectedOption.taxas
      formData.total = selectedOption.total
      formData.rota = selectedOption.rota
      formData.numero_contrato = selectedOption.numero_contrato
      formData.servico_com_transbordo = selectedOption.servico_com_transbordo

      ProposalService.postNewProposal(formData).then((res) => {
        this.isLoading = false
        this.$router.push({ name: 'ProposalCreate', params: { id: res.data.data.id } })
      })
    },
    selectSortOrder (sortBy) {
      this.filterData.sortBy = sortBy

      this.onSearch(this.value)
    },
    equipmentLoadOptions () {
      let _this = this

      let promise = new Promise((resolve, reject) => {
        let filters = {
          'modality': _this.filterData.modality.id,
          'quotations': true
        }

        EquipmentService.getEquipments(filters).then(res => {
          let items = []

          for (let i in res.data.data) {
            let item = res.data.data[i]

            item.quantity = 0

            for (let sI in _this.filterData.equipments) {
              let selected = _this.filterData.equipments[sI]

              if (selected.id === item.id) {
                item.quantity = selected.quantity
                break
              }
            }
            items.push(item)
          }
          resolve(items)
        }).catch((error) => {
          reject(error)
        })
      })

      return promise
    },
    onSearchSuggested () {
      let _this = this

      _this.filterData.estimatedDate = _this.maxValidity
      _this.onSearch()
    },
    onSearch (value) {
      let _this = this
      _this.filtered = false
      _this.isLoading = true
      _this.resultType = 1
      _this.searchBy = []

      let isFcl = _this.filterData.modality.id === 2

      // Save last filter
      global.instanceApp.$Cookie.set('_proposal_last_search_', JSON.stringify(_this.filterData))

      let filters = {
        operation: _this.filterData.operation.id,
        modality: _this.filterData.modality.id,
        commoditie: _this.filterData.commoditie.id,
        origin: _this.filterData.origin.id,
        destination: _this.filterData.destination.id,
        estimatedDate: _this.filterData.estimatedDate,
        sortBy: _this.filterData.sortBy.id,
        incoterm: _this.filterData.incoterm.id
      }

      if (isFcl) {
        filters.equipments = _this.filterData.equipments.map((equipment) => {
          return {
            id: equipment.id,
            descricao: equipment.descricao,
            quantity: equipment.quantity
          }
        })
      } else {
        filters.cbm = _this.$util.getNumberFromFormated(_this.filterData.cbm)
        filters.peso_bruto = _this.$util.getNumberFromFormated(_this.filterData.peso_bruto)
      }

      if (_this.filterData.customer && _this.filterData.customer.id) {
        filters.customer = _this.filterData.customer.id
      }

      if (isFcl) {
        TariffService.getTariffs(filters).then((res) => {
          _this.tariffOptions = res.data.data
          _this.sortOrderCards = res.data.sortCards
          _this.availableCupom = (res.data.availableCupom !== false) ? res.data.availableCupom : null
          _this.filtered = res.data.searchInfo
          _this.resultType = res.data.resultType
          _this.searchBy = res.data.searchBy
          _this.searchDate = res.data.searchDate
          _this.maxValidity = res.data.maxValidity
          _this.searchTerms = res.data.searchTerms
        }).finally(() => {
          _this.isLoading = false
        })
      } else {
        TariffService.getLclTariffs(filters).then((res) => {
          _this.tariffOptions = res.data.data
          _this.availableCupom = (res.data.availableCupom !== false) ? res.data.availableCupom : null
          _this.filtered = res.data.searchInfo
          _this.resultType = res.data.resultType
          _this.searchBy = res.data.searchBy
          _this.searchDate = res.data.searchDate
          _this.maxValidity = res.data.maxValidity
          _this.searchTerms = res.data.searchTerms
        }).finally(() => {
          _this.isLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>

.card .value {
  font-size: 14px;
}
.spotlight {
  color: #84754e;
  font-weight: bold;
}

.sort-card {
  background-color: #fff;
}

.custom-dropdown-menu {
  border: 1px solid #ccc !important;
  border-radius: 7px !important;
  box-sizing: border-box !important;
}

.custom-dropdown-item {
  color: gray !important;
}

.price-div {
  margin: auto !important;
}

.cinza {
  color: gray;
}

.proposal-no-options {
  margin-top: 30px;
}

.proposal-no-options div {
  color: #717274;
  font-size: 98%;
  margin-bottom: 20px;

}

.proposal-no-options .title {
  color: #3d3d3d;
  font-size: 160%;
}

.float-left.text-content {
  width: calc(90% - 100px);
}

</style>
