<template>
  <div :class="classRoot">
    <div class="card" :class="{ 'card-black': black }" >
      <div class="card-body no-padding no-margin">
        <div class="row">
          <div class="col col-12">
            <div class="row">
              <div class="card-title" v-if="$slots.title">
                <slot name="title"></slot>
              </div>
              <div class="card-sort" v-if="$slots.sort">
                <slot name="sort"></slot>
              </div>
            </div>
            <div class="row">
              <div class="col col-6 mt-3">
                <slot name="content-1"></slot>
              </div>
              <div class="col col-6 mt-3" >
                <slot name="content-2"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-legend" v-if="$slots.legend">
        <slot name="legend"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  components: {
  },
  props: {
    black: {
      type: Boolean,
      default: false,
      required: false
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    classRoot: {
      type: [String, Object, Array],
      default: 'card-fullheight'
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .card:hover {
    cursor: pointer;
    background-color: #dfdfdf;
  }

  .card-black {
    background-color: #3D3D3D;
  }

  .card-black:hover {
    cursor: pointer;
    background-color: #2d2d2d;
  }

  .card {
    margin: 0px;
    padding: 16px 24px;
  }

  .card-sort {
    position: absolute;
    top: -11px;
    right: -20px;
  }

  .card-title, .card-title b {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .no-padding, .no-padding .row {
    padding: 0;
  }
  .no-margim, .no-margim .row {
    margin: 0;
  }

  .no-padding .col{
    padding: 0;
  }

  .card-black .card-title {
    color: #B1B1B1;
  }
  .card-black .card-body {
    color: #fefefe;
  }

  .grafico {
    width: 70px;
    height: 70px;
    background-color: lightgrey;
    font-size: 9px;
    text-align: center;
  }

  .value {
    font-size: 20px;
  }
</style>
